import React, { useState } from 'react';
import ReactCodeInput from 'react-code-input';
import { Card } from 'primereact/card';
import { Button } from 'primereact/button';

import { useAuth } from '../../context/Auth';
import { encrypt } from '../../utils/encryptPassword';

export const GenerateQRCode = () => {
  const { email, password, userSecret, setUserSecret, userQRCode, setUserQRCode, createUserSecret } = useAuth();

  const [token, setToken] = useState('');

  const handleSubmit = event => {
    event.preventDefault();

    createUserSecret({
      email: email,
      secret: userSecret,
      password: encrypt(password),
      token,
    });
  };

  return (
    <Card style={{ width: '27em', height: 'fit-content' }}>
      <form>
        <h4 style={{ marginBottom: '2rem', textAlign: 'center' }}>Autenticação da Conta</h4>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '2rem' }}>
          <div style={{ display: 'flex', flexDirection: 'column', gap: '0.5rem' }}>
            <span style={{ fontSize: '14px', fontWeight: 600, color: '#21303D' }}>
              Passo 1: Baixe o aplicativo de autenticação
            </span>
            <span style={{ fontSize: '12px', color: '#80868B' }}>
              Baixe e instale o aplicativo Google Authenticator ou Microsoft Authenticator no seu celular.
            </span>
          </div>

          <div style={{ display: 'flex', flexDirection: 'column', gap: '0.5rem' }}>
            <span style={{ fontSize: '14px', fontWeight: 600, color: '#21303D' }}>Passo 2: Escaneie o QR code</span>
            <span style={{ fontSize: '12px', color: '#80868B' }}>
              Escaneie o código QR abaixo através do seu celular para realizar a configuração do seu token de
              autenticação.
            </span>
            <center>
              <img src={userQRCode} alt='qrcode' height='150' width='150' />
            </center>
          </div>

          <div style={{ display: 'flex', flexDirection: 'column', gap: '0.5rem' }}>
            <span style={{ fontSize: '14px', fontWeight: 600, color: '#21303D' }}>Passo 3: Verifique seu código</span>
            <span style={{ fontSize: '12px', color: '#80868B' }}>
              Insira abaixo o código de 6 dígitos gerado pelo seu aplicativo de autenticação.
            </span>
            <center>
              <ReactCodeInput type='text' fields={6} onChange={e => setToken(e)} />
            </center>
          </div>
        </div>

        <div style={{ display: 'flex', gap: '1rem', marginTop: '2rem', justifyContent: 'end' }}>
          <Button
            type='button'
            className='p-button'
            onClick={() => {
              setUserSecret(undefined);
              setUserQRCode(undefined);
            }}
            style={{
              background: '#ECF0F3',
              width: '8em',
              height: '2.5em',
              borderColor: '#ECF0F3',
              justifyContent: 'center',
              fontSize: '14px',
              fontWeight: 600,
              color: '#253746',
            }}>
            Cancelar
          </Button>
          <Button
            type='submit'
            className='p-button'
            onClick={handleSubmit}
            style={{
              background: '#253746',
              width: '7em',
              height: '2.5em',
              borderColor: '#253746',
              justifyContent: 'center',
              fontSize: '14px',
              fontWeight: 800,
              color: '#FFF',
            }}>
            Validar
          </Button>
        </div>
      </form>
    </Card>
  );
};
