import dotEnv from 'dotenv';

import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';

import Routes from './routes/index.routes';

import AppProvider from './context/index';

dotEnv.config();

ReactDOM.render(
  <BrowserRouter>
    <AppProvider>
      <Routes />
    </AppProvider>
  </BrowserRouter>,
  document.getElementById('root'),
);
