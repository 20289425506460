import React from 'react';

import Toast from './Toast';
import Auth from './Auth';

const AppProvider = ({ children }) => {
  return (
    <Toast>
      <Auth>{children}</Auth>
    </Toast>
  );
};

export default AppProvider;
