import { Button } from 'primereact/button';
import { Card } from 'primereact/card';
import React, { useState } from 'react';

import { MultiSelectComponent } from '../../components/MultiSelect';
import { useAuth } from '../../context/Auth';
import logo from '../../layout/images/bioparkLogo.png';

export const SelectRole = ({ group }) => {
  const { handleCreateUser } = useAuth();

  const [disableButton, setDisableButton] = useState(false);
  const [selectedRoles, setSelectedRoles] = useState([]);
  const [hasToSelectRole, setHasToSelectRole] = useState(false);
  const roleOptions =
    group === 'business'
      ? [
          { id: '4ca33b07-b757-4137-8f65-a047e2e160d3', description: 'SDR' },
          { id: '77f1aed3-a548-4027-a2de-5e2390fbe5f8', description: 'Analista' },
          { id: '88143a66-1dae-434c-bc65-14883f2da1f6', description: 'Analista Queijaria' },
        ]
      : [{ id: '3803fed9-385c-4b69-87e2-e97685afaf64', description: 'Multiplicador' }];

  const handleSubmitWithRoles = async () => {
    setDisableButton(true);

    if (selectedRoles && selectedRoles.length > 0) {
      setHasToSelectRole(false);
      await handleCreateUser(selectedRoles.map(role => role.id));
    } else {
      setHasToSelectRole(true);
    }

    setDisableButton(false);
  };

  return (
    <Card style={{ width: '27em', height: 'fit-content' }}>
      <form>
        <center>
          <img src={logo} alt='logo' height='90' width='266' />
        </center>
        <h4 style={{ marginTop: '3rem', marginBottom: '2rem', textAlign: 'center' }}>Selecionar Cargo</h4>
        <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '2rem' }}>
          <span
            style={{
              textAlign: 'center',
              fontSize: '12px',
              color: '#80868B',
              width: '70%',
            }}>
            Para continuar, selecione seu(s) cargo(s) no campo abaixo.
          </span>
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom: '5rem' }}>
          <MultiSelectComponent
            value={selectedRoles}
            options={roleOptions}
            onChange={e => setSelectedRoles(e.value)}
            style={{ width: '80%' }}
            className={`${hasToSelectRole ? 'p-invalid' : ''}`}
          />
          {hasToSelectRole && (
            <small id='sdr' className='p-error p-d-block'>
              Nenhum cargo selecionado
            </small>
          )}
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
          <Button
            type='button'
            className='p-button'
            onClick={handleSubmitWithRoles}
            disabled={disableButton}
            style={{
              justifyContent: 'center',
              background: '#253746',
              width: '50%',
              height: '2.5em',
              borderColor: '#253746',
              fontSize: '14px',
              fontWeight: 800,
              color: '#FFF',
            }}>
            Avançar
          </Button>

          <a
            onClick={() => {
              if (!disableButton) {
                setDisableButton(true);
                handleCreateUser();
              }
            }}
            style={{ cursor: 'pointer', marginTop: '1rem', justifyContent: 'center', alignItems: 'center' }}>
            <span
              style={{
                userSelect: 'none',
                textAlign: 'center',
                fontSize: '10px',
                color: '#80868B',
                width: '100%',
              }}>
              Pular etapa
            </span>
            {/* <i class='pi pi-arrow-right ml-2' style={{ fontSize: '8px', color: '#80868B' }} /> */}
          </a>
        </div>
      </form>
    </Card>
  );
};
