import React from 'react';
import { ProgressSpinner } from 'primereact/progressspinner';

import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import './layout/flags/flags.css';
import './layout/layout.scss';

// import { useAuth } from './context/Auth';

const App = () => {
  // const { verifyToken } = useAuth();

  // useEffect(() => {
  //   verifyToken();
  // }, []);

  return (
    <div
      style={{
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}>
      <ProgressSpinner />
      <h4>Aguarde...</h4>
    </div>
  );
};

export default App;
