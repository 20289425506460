import React from 'react';
import { Switch } from 'react-router-dom';

import Route from './Route';
import { LoginPage } from '../pages/Login';
import App from '../App';
import { ModuleSelection } from '../pages/ModuleSelection';

const Routes = () => {
  return (
    <Switch>
      <Route path='/' exact component={LoginPage} />

      <Route path='/modulos' component={ModuleSelection} isPrivate />
      <Route path='/carregando' component={App} isPrivate />
    </Switch>
  );
};

export default Routes;
