import React from 'react';
import { Route as ReactDOMRoute, Redirect } from 'react-router-dom';

import { useAuth } from '../context/Auth';

const Route = ({ isPrivate = false, component: Component, ...rest }) => {
  const { user } = useAuth();

  return (
    <ReactDOMRoute
      {...rest}
      render={({ location }) => {
        return isPrivate === !!user ? ( // se é privado e o usuário esta logado
          <Component />
        ) : (
          <Redirect // se é privado e o usuário não está logado
            to={{
              pathname: isPrivate ? '/' : '/carregando',
              state: { form: location },
            }}
          />
        );
      }}
    />
  );
};

export default Route;
