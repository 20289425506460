import React, { createContext, useState, useContext, useEffect } from 'react';
import jwt from 'jsonwebtoken';
import { useHistory } from 'react-router-dom';
import qrcode from 'qrcode';

import api from '../../services/api';

import { useToast } from '../Toast';

const AuthContext = createContext();

const PUBLIC_KEY =
  '-----BEGIN PUBLIC KEY-----\nMIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEA3POpb9/1PwBK9A3vBfXX\nTJuGhTMy8CreeFXEM19/WB6bLqhIXE7IzH40KNnfWnQn1twMshViJBN9eHAiYErn\nF5dJrzjWtIp9xrFhmquYvz/2RyeVflWXH/ZmfO1v15nF7tKjN3+WTM4rAY9wGssl\nGahvs6ET0rp2PG0PLJXXEvYNxHp1OpP21xrWepb3RXCxlCqARq//UNENgFyazpsx\n9Q/V15xvlmUT74mYOGMMEhy/Xw71SEMr/rOElXj2cGZ65fgeBl+vi7Fj/0Z7jk23\nKa4iuaXxElys8cieok77KJrhwFoRae4cJgjY86SfYgipc5PwepOtu1S5k3NRtIEV\nAQIDAQAB\n-----END PUBLIC KEY-----\n'; // fs.readFileSync('public-key.pem');

export default function Auth({ children }) {
  const navigate = useHistory();
  const { showToast } = useToast();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [user, setUser] = useState(undefined);
  const [selectUserRole, setSelectUserRole] = useState(undefined);
  const [userSecret, setUserSecret] = useState(undefined);
  const [userQRCode, setUserQRCode] = useState(undefined);
  const [isLoading, setIsLoading] = useState(false);

  const handleRedirect = (sector, roles) => {
    window.name = 'gdi';

    switch (sector) {
      case 'Directors':
        navigate.push('/modulos');
        break;
      case 'financial':
        navigate.push('/modulos');
        break;
      case 'Marketing':
        navigate.push('/modulos');
        break;
      case 'business':
        if (roles.includes('MULTIPLICADOR')) {
          navigate.push('/modulos');
        } else {
          window.open(process.env.REACT_APP_GDI_NEGOCIOS_URL, 'gdi');
        }
        break;
      case 'resident':
        if (roles.includes('SDR') || roles.includes('ANALISTA')) {
          navigate.push('/modulos');
        } else {
          window.open(process.env.REACT_APP_GDI_SUCESSO_URL, 'gdi');
        }
        break;
      case 'sales':
        window.open(process.env.REACT_APP_GDI_COMERCIAL_URL, 'gdi');
        break;
      case 'providers':
        window.open(process.env.REACT_APP_GDI_COMPRAS_URL, 'gdi');
        break;
      case 'queijariaGDI':
        window.open(process.env.REACT_APP_GDI_CHEESE_FACTORY_URL, 'gdi');
        break;
      default:
        showToast('error', 'Erro', 'Ocorreu um erro ao redirecionar.');
    }
  };

  const getCookie = () => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; @Biopark:token=`);
    if (parts.length === 2) return parts?.pop()?.split(';').shift();
  };

  const verifyToken = () => {
    const tokenFromCookie = getCookie();

    if (tokenFromCookie) {
      const token = tokenFromCookie.replace('@Biopark:token=', '');

      if (token) {
        try {
          jwt.verify(token, PUBLIC_KEY);
        } catch (error) {
          // para deletar o cookie basta colocar uma data de expiração passada
          document.cookie = '@Biopark:token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
        }

        const { name, sector, roles } = jwt.decode(token);

        setUser({
          name,
          sector,
          roles,
        });
        handleRedirect(sector, roles);
      }
    }
  };

  const handleCreateUser = async roles => {
    try {
      const response = await api.post('/session/create-user', {
        email,
        password,
        description: selectUserRole.description,
        sector: selectUserRole.sector,
        roles,
      });

      if (response.data.token) {
        setSelectUserRole(undefined);
        saveToken(response.data.token);
        verifyToken();
      }
    } catch (error) {
      showToast('error', 'Erro!', 'Falha ao criar novo usuário!');
    }
  };

  const createUserSecret = async data => {
    try {
      const response = await api.post('/session/create-secret', data);

      if (response.data.token) {
        saveToken(response.data.token);
        verifyToken();
      }
    } catch (error) {
      showToast('error', 'Dados Inválidos', 'Verifique se você digitou os dados corretamente!');
    }
  };

  const validateToken = async data => {
    try {
      const response = await api.post('/session/validate', data);

      if (response.data.token) {
        saveToken(response.data.token);
        verifyToken();
      }
    } catch (error) {
      showToast('error', 'Dados Inválidos', 'Verifique se você digitou os dados corretamente!');
    }
  };

  const signIn = async ({ email, password }) => {
    try {
      setIsLoading(true);
      const response = await api.post('/session/login', {
        user: email,
        password,
      });

      if (response.data.otpauth_url) {
        generateQRCode(response.data.otpauth_url);
      } else if (response.data.message) {
        if (response.data.message === 'user_has_generated_tfa') {
          setUserSecret(response.data.message);
        } else if (response.data.message === 'user_not_created') {
          setSelectUserRole(response.data.user);
        } else {
          showToast('error', 'Erro!', response.data.message);
        }
      } else {
        const { token } = response.data;

        saveToken(token);
        verifyToken();
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      showToast('error', 'Dados Inválidos', 'Verifique se você digitou os dados corretamente!');
    }
  };

  const generateQRCode = secret => {
    try {
      qrcode.toDataURL(`otpauth://totp/Biopark%20GDI?secret=${secret}`, (err, data) => {
        setUserQRCode(data);
      });
      setUserSecret(secret);
    } catch (err) {
      showToast('error', 'Erro Interno', 'Erro ao gerar QRCode, tente novamente!');
    }
  };

  const saveToken = token => {
    document.cookie = `@Biopark:token=${token}`;
    window.name = 'gdi';
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        password,
        setPassword,
        email,
        setEmail,

        userSecret,
        setUserSecret,
        userQRCode,
        setUserQRCode,
        selectUserRole,

        handleCreateUser,
        createUserSecret,
        validateToken,
        signIn,
        verifyToken,

        isLoading,
      }}>
      {children}
    </AuthContext.Provider>
  );
}

export function useAuth() {
  const context = useContext(AuthContext);

  return context;
}
