import React, { useState } from 'react';
import ReactCodeInput from 'react-code-input';
import { Button } from 'primereact/button';
import { Card } from 'primereact/card';

import { useAuth } from '../../context/Auth';
import { encrypt } from '../../utils/encryptPassword';
import logo from '../../layout/images/bioparkLogo.png';

export const ValidateToken = () => {
  const { email, password, setUserSecret, setUserQRCode, validateToken } = useAuth();

  const [token, setToken] = useState('');

  const handleSubmit = event => {
    event.preventDefault();

    validateToken({
      email: email,
      password: encrypt(password),
      token,
    });
  };

  return (
    <Card style={{ width: '27em', height: 'fit-content' }}>
      <form>
        <center>
          <img src={logo} alt='logo' height='90' width='266' />
        </center>
        <h4 style={{ marginTop: '3rem', marginBottom: '2rem', textAlign: 'center' }}>Autenticação da Conta</h4>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
          <span style={{ fontSize: '13px', color: '#80868B', textAlign: 'center' }}>
            Insira o código de 6 dígitos que aparece em seu aplicativo de autenticação.
          </span>
          <center>
            <ReactCodeInput type='text' fields={6} onChange={e => setToken(e)} />
          </center>
        </div>
        <div style={{ gap: '1rem', marginTop: 62, display: 'flex', justifyContent: 'center' }}>
          <Button
            type='button'
            className='p-button'
            onClick={() => {
              setUserSecret(undefined);
              setUserQRCode(undefined);
            }}
            style={{
              background: '#ECF0F3',
              justifyContent: 'center',
              width: '8em',
              height: '2.5em',
              borderColor: '#ECF0F3',
              fontSize: '14px',
              fontWeight: 600,
              color: '#253746',
            }}>
            Voltar
          </Button>
          <Button
            type='submit'
            className='p-button'
            onClick={handleSubmit}
            style={{
              background: '#253746',
              justifyContent: 'center',
              width: '7em',
              height: '2.5em',
              borderColor: '#253746',
              fontSize: '14px',
              fontWeight: 800,
              color: '#FFF',
            }}>
            Validar
          </Button>
        </div>
        <span>
          <center style={{ marginTop: '75px' }}>
            <h6>Todos os direitos reservados &copy; 2022</h6>
          </center>
        </span>
      </form>
    </Card>
  );
};
