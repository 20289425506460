import React, { useState } from 'react';
import { Card } from 'primereact/card';
import { Button } from 'primereact/button';
import { Password } from 'primereact/password';
import { InputText } from 'primereact/inputtext';

import { useAuth } from '../../context/Auth';
import { encrypt } from '../../utils/encryptPassword';
import { useToast } from '../../context/Toast';
import logo from '../../layout/images/bioparkLogo.png';

export const Login = () => {
  const { email, setEmail, password, setPassword, signIn, isLoading } = useAuth();
  const { showToast } = useToast();

  const [usernameClass, setUsernameClass] = useState('');
  const [passwordClass, setPasswordClass] = useState('');

  const newDate = new Date()
  const year = newDate.getFullYear()

  const handleKeypress = e => {
    if (e === 'Enter') {
      handleSignIn();
    }
  };

  async function handleSignIn() {
    const validationUsername = !!email;
    const validationPassword = !!password;

    if (validationPassword && validationUsername) {
      await signIn({
        email,
        password: encrypt(password),
      });
    } else {
      setUsernameClass(!validationUsername ? 'p-invalid' : '');
      setPasswordClass(!validationPassword ? 'p-invalid' : '');

      showToast('error', 'Credenciais Inválidas', 'Dados Inválidos!');
    }
  }

  return (
    <Card style={{ width: '27em', height: 'fit-content' }}>
      <center>
        <img src={logo} alt='logo' height='90' width='266' />
      </center>

      <h4 style={{ display: 'flex', justifyContent: 'center', marginTop: '3rem', marginBottom: '2rem' }}>
        Credenciais
      </h4>
      <form>
        <div className='p-fluid'>
          <div className='p-field' style={{ marginBottom: 25 }}>
            <div>
              <InputText
                id='email'
                value={email || ''}
                onChange={e => setEmail(e.target.value)}
                placeholder='Usuário'
                className={`${usernameClass}`}
              />
            </div>

            <div style={{ position: 'fixed' }}>
              {usernameClass === 'p-invalid' && (
                <small id='username2-help' className='p-error p-d-block'>
                  Usuário Inválido
                </small>
              )}
            </div>
          </div>

          <div className='p-field' style={{ marginBottom: 25 }}>
            <div>
              <Password
                value={password || ''}
                onChange={e => setPassword(e.target.value)}
                toggleMask
                feedback={false}
                placeholder='Senha'
                className={`${passwordClass}`}
                onKeyPress={e => handleKeypress(e.key)}
                autoComplete='off'
              />
            </div>

            <div style={{ position: 'fixed' }}>
              {passwordClass === 'p-invalid' && <small className='p-error p-d-block'>Senha Inválida</small>}
            </div>
          </div>
        </div>
      </form>
      <div className='p-d-flex p-jc-center'>
        <Button
          type='submit'
          label='Acessar'
          className='p-button'
          style={{ background: '#253746', width: '100%', borderColor: '#253746', fontSize: '16px', marginTop: 20 }}
          onClick={handleSignIn}
          loading={isLoading}
        />
      </div>
      <span>
        <center style={{ marginTop: '84px', marginBottom: '24px' }}>
          <h6>Todos os direitos reservados &copy; {year}</h6>
        </center>
      </span>
    </Card>
  );
};
