import React, { useEffect } from 'react';

import { useAuth } from '../../context/Auth';
import { GenerateQRCode } from './GenerateQRCode';
import { Login } from './Login';
import { SelectRole } from './SelectRole';
import { ValidateToken } from './ValidateToken';

import './Login.scss';

export const LoginPage = () => {
  const { verifyToken, userSecret, userQRCode, selectUserRole } = useAuth();

  useEffect(() => {
    verifyToken();
  }, []);

  return (
    <div className='container'>
      {userQRCode ? (
        <GenerateQRCode />
      ) : selectUserRole ? (
        <SelectRole group={selectUserRole.group} />
      ) : userSecret ? (
        <ValidateToken />
      ) : (
        <Login />
      )}
    </div>
  );
};
