import { Button } from 'primereact/button';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { useAuth } from '../../context/Auth';
import logo from '../../layout/images/bioparkLogoBrancoRosa.svg';

export const ModuleSelection = () => {
  const navigate = useHistory();
  const { user } = useAuth();

  const comercial = {
    url: process.env.REACT_APP_GDI_COMERCIAL_URL,
    name: 'Comercial',
    icon: 'pi-money-bill',
  };

  const sucesso = {
    url: process.env.REACT_APP_GDI_SUCESSO_URL,
    name: 'Sucesso',
    icon: 'pi-building',
  };

  const negocios = {
    url: process.env.REACT_APP_GDI_NEGOCIOS_URL,
    name: 'Negócios',
    icon: 'pi-briefcase',
  };

  const compras = {
    url: process.env.REACT_APP_GDI_COMPRAS_URL,
    name: 'Compras',
    icon: 'pi-tags',
  };

  const moduleSelectionOptionsByAdGroup = {
    Marketing: {
      title: 'Acesso Marketing',
      modules: [comercial, negocios],
    },
    Directors: {
      title: 'Acesso Marketing',
      modules: [comercial, compras, negocios, sucesso],
    },
    business: {
      title: 'Acesso Negócios',
      modules: [negocios, sucesso],
    },
    resident: {
      title: 'Acesso Negócios',
      modules: [negocios, sucesso],
    },
    financial: {
      title: 'Acesso Financeiro',
      modules: [comercial, sucesso],
    },
  };

  // IDEIA PARA QUANDO FOI APRIMORADO ESSE MÓDULO.
  // Baseado nos grupos do AD que a pessoa estiver, concatena os acessos e inclui para seleção
  // Hoje as pessoas podem estar em apenas um grupo
  // const modulesOpetions = {
  //   sales: comercial,
  //   business: negocios,
  //   resident: sucesso,
  //   prpviders: compras,
  // };

  // const enabledModuleToSelect =
  //   user?.sector?.map(sector => {
  //     if (modulesOpetions[sector]) return modulesOpetions[sector];
  //   }) ?? [];

  return (
    <div className='container p-flex-column'>
      <div className='p-mb-4'>
        <img src={logo} alt='logo' height='120' width='300' />
      </div>

      <div>
        <h4 style={{ color: '#fcfcfc' }}>{moduleSelectionOptionsByAdGroup[user.sector].title}</h4>
      </div>

      <div className='p-d-flex p-flex-row p-flex-wrap p-jc-center p-mt-4' style={{ gap: '1.5rem' }}>
        {moduleSelectionOptionsByAdGroup[user.sector].modules.map(module => (
          <ModuleSelectButton
            key={module.url}
            onClick={() => {
              navigate.push('/carregando');
              window.open(module.url, 'gdi');
            }}
            moduleName={module.name}
            icon={module.icon}
          />
        ))}
      </div>
    </div>
  );
};

const ModuleSelectButton = ({ onClick, moduleName, icon }) => {
  return (
    <Button
      // id='module-button'
      className='p-button-raised p-button-text p-d-flex p-flex-column module-button'
      style={{ backgroundColor: '#eb0045', color: '#F7F8F9' }}
      onClick={onClick}>
      <div className='p-d-flex p-ai-center p-p-2'>
        <i className={`pi ${icon}`} style={{ fontSize: '2em', fontWeight: 'normal' }} />
        <h3 className='p-m-2' style={{ fontWeight: 'lighter' }}>
          {moduleName}
        </h3>
      </div>
    </Button>
  );
};
