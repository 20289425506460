import { MultiSelect } from 'primereact/multiselect';
import React from 'react';

export function MultiSelectComponent({
  options,
  value,
  onChange,
  className = 'p-column-filter',
  style = { marginRight: '5px', width: '23rem' },
  optionLabel = 'description',
  disabled,
}) {
  const ItemTemplate = option => {
    return <span>{option[optionLabel]}</span>;
  };

  return (
    <MultiSelect
      showSelectAll={false}
      value={value}
      options={options}
      onChange={onChange}
      filterBy={optionLabel}
      optionLabel={optionLabel}
      placeholder='Clique para exibir'
      selectedItemsLabel='{0} Itens Selecionados'
      display='chip'
      className={className}
      style={style}
      itemTemplate={ItemTemplate}
      disabled={disabled}
    />
  );
}
